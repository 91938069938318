import { matchesEnvironment } from 'utils/config';

const allowReturnToUrl = (url: URL) => {
  const { hostname } = url;

  if (
    matchesEnvironment('uat') ||
    matchesEnvironment('passport') ||
    matchesEnvironment('production')
  ) {
    return window._config?.allowedReturnToUrls.includes(hostname) || false;
  }
  return true;
};

export default allowReturnToUrl;
