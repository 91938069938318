import React, { ReactNode } from 'react';
import { JanusHookshotProvider, SwrOptions } from '@bw/hookshot';
import urlcat from 'urlcat';
import config from './utils/config';

const swrOptions: SwrOptions = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
};

interface ApiProviderProps {
  children?: ReactNode;
}

const customFetch: typeof window.fetch = (input, init = {}) => {
  let request;
  if (typeof input === 'string') {
    request = urlcat(config.irisApi, input);
  } else {
    const newInput = new Request(input);
    request = {
      ...newInput,
      url: urlcat(config.irisApi, newInput.url),
    };
  }

  return window.fetch(request, {
    ...init,
    // eslint-disable-next-line
    headers: { 'Content-Type': 'application/xml', ...init.headers },
  });
};

/** Provides configured hookshot settings to passport. */
const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => (
  <JanusHookshotProvider customFetch={customFetch} swrOptions={swrOptions}>
    {children}
  </JanusHookshotProvider>
);

export default ApiProvider;
