import { Loader, Box } from '@bw/foundry';
import React, { useEffect, useState } from 'react';

export type DelayLoaderProps = {
  delay: number;
};

const DelayLoader: React.FC<DelayLoaderProps> = ({ delay }) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(id);
  });

  if (!showLoader) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
    >
      <Loader />
    </Box>
  );
};

export default DelayLoader;
