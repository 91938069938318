import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@bw/foundry';

import PassportRoutes from './Routes';
import ApiProvider from './ApiProvider';
import './datadog';

/** Top level application component. */
const App = () => (
  <StyledEngineProvider injectFirst>
    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
      <ApiProvider>
        <CssBaseline>
          <ThemeProvider>
            <PassportRoutes />
          </ThemeProvider>
        </CssBaseline>
      </ApiProvider>
    </BrowserRouter>
  </StyledEngineProvider>
);
export default App;
