import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const initVersion = (win: any) => {
  // eslint-disable-next-line no-param-reassign
  win.__passport__ = process.env.REACT_APP_VERSION;
};

initVersion(window);
root.render(<App />);
