import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import config, { matchesEnvironment } from './utils/config';

datadogRum.init({
  ...config.datadog,
  site: 'datadoghq.com',
  service: 'passport',
  version: config.version,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});
datadogRum.addRumGlobalContext('app', 'ui');
datadogRum.addRumGlobalContext('component', 'passport');
datadogRum.addRumGlobalContext('owner', 'cx-forge');

datadogLogs.init({
  ...config.datadog,
  site: 'datadoghq.com',
  service: 'passport',
  version: config.version,
  forwardErrorsToLogs: !matchesEnvironment('local'),
  sampleRate: 100,
});
