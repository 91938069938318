interface WindowPostMessageArgs {
  isAuthenticated: boolean;
  originalUri?: string;
}

const windowPostMessage = ({
  isAuthenticated,
  originalUri,
}: WindowPostMessageArgs) => {
  window?.opener?.postMessage({ isAuthenticated }, originalUri);
};

export default windowPostMessage;
